
import { useI18n } from "vue-i18n";
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";

export default {
  name: "IdentificationSectionTE",
  props: {
    data_table: String,
  },
  emits: ["createElement", "removeElement"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const addElement = ref(false);
    const isLoading = ref(false);
    const tableData = ref<[] | any>([]);
    const headerTable = ref([
      {
        column: "iidentificationtypes",
        key: "identification_type_name",
      },
      { column: "ivalue", key: "value" },
    ]);
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const createElement = () => {
      emit("createElement");
    };

    const removeElement = (index) => {
      tableData.value.splice(index, 1);
    };

    watch(
      () => props.data_table,
      (first) => {
        tableData.value = first;
      },
      { deep: true }
    );
    const getIdentificationTypes = (query) => {
      ApiService.get(
        "/api/identification-type/?name=" + query + "&per_page=5"
      ).then(function (response) {
        elements.value.options = response.data.identification_type;
      });
    };

    onMounted(() => {
      tableData.value = props.data_table;
      ApiService.get("/api/identification-type").then(function (response) {
        elements.value.options = response.data.identification_type;
      });
    });

    return {
      getIdentificationTypes,
      addElement,
      isLoading,
      tableData,
      headerTable,
      elements,
      removeElement,
      createElement,
    };
  },
};
