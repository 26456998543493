
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default {
  name: "EntityTypesTE",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement", "fieldSelected"],
  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const dataTable = computed(() => store.getters.EntityTypes);
    const selector = ref([]);
    const loadEntityData = (id, index) => {
      ApiService.get("/api/type-entities/" + id).then(function (response) {
        dataTable.value[index].description = response.data.description;
      });
    };

    const addElement = (value) => {
      if (dataTable.value.find((o) => o.id === value.id) === undefined) {
        store.commit("createEntityTypes", {
          id: value.id,
          name: value.name,
          description: value.description,
        });
      }
    };
    const fieldSelector = () => {
      if (selector.value.find((o) => o === 0) === 0) {
        elements.value.options.forEach(function (value) {
          addElement(value);
          selector.value = [];
        });
      } else {
        selector.value.forEach(function (value) {
          let data = elements.value.options.find((o) => o.id === value);
          addElement(data);
          selector.value = [];
        });
      }
    };
    const headers = ref([
      { label: "iname", prop: "name" },
      { label: "idescription", prop: "description" },
    ]);
    const headerTable = ref(headers.value);
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const contactSelected = ref({
      contact_type_id: 1,
      entities: route.params.id,
    });
    onMounted(() => {
      ApiService.get("/api/type-entities/lists?per_page=1000").then(function (
        response
      ) {
        elements.value.options = response.data.type_entities;
      });
    });

    return {
      fieldSelector,
      selector,
      elements,
      dataTable,
      loading,
      headers,
      headerTable,
      contactSelected,
      loadEntityData,
    };
  },
};
