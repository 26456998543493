
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

import { is } from "@vee-validate/rules";
import store from "@/store";

export default {
  name: "OfficesTE",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    disable: {
      type: Boolean,
      default: false,
    },
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement", "fieldSelected"],

  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const dataTable = computed(() => store.getters.Offices);
    const selector = ref([]);
    const inputOffice = ref({
      loading: false,
    });

    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };

    const userOffices = ref("");
    const permissions = computed(() => store.getters.permissions);
    const canDeleteOffices = computed(() => {
      return (
        permissions.value.findIndex((x) => x.name === "offices: delete") !== -1
      );
    });
    const selectOffices = (query) => {
      if (query !== "") {
        inputOffice.value.loading = true;
        setTimeout(() => {
          inputOffice.value.loading = false;
          store.commit("setLoading", false);
          ApiService.query("api/offices/lists", {
            params: {
              per_page: 25,
              name: query,
              users: [user.value.id],
            },
          }).then(({ data }) => {
            elements.value.options = data.offices;
            inputOffice.value.loading = false;
          });
        }, 200);
      }
    };

    const headers = ref([
      { label: "iname", prop: "name" },
      { label: "iremarks", prop: "remarks" },
    ]);
    const headerTable = ref(headers.value);

    const addElement = (value) => {
      if (props.tableData.find((o) => o.id === value.id) === undefined) {
        store.commit("createOffice", {
          id: value.id,
          name: value.name,
          remarks: value.remarks,
        });
        
        // emit('createElement', value)
      }
    };
    const fieldSelector = () => {
      if (selector.value.find((o) => o === 0) === 0) {
        elements.value.options.forEach(function (value) {
          addElement(value);
        });
        selector.value = [];
      } else {
        selector.value.forEach(function (value) {
          let data = elements.value.options.find((o) => o.id === value);
          if (data) {
            addElement(data);
          }
        });
        selector.value = [];
      }
    };
    const getData = () => {
      //
    };

    const elements = ref({
      loading: false,
      options: store.getters.AllOffices,
      list: store.getters.AllOffices,
    }) as any;

    const contactSelected = ref({
      contact_type_id: 1,
      entities: route.params.id,
    });

    // watch(
    //   () => props.tableData,
    //   (first) => {
    //     if (!elements.value.options.length) {
    //       elements.value.options = first;
    //     }
    //   }
    // );

    onMounted(() => {
      selectOffices(" ");
      elements.value.options = [];
      if (!elements.value.options.length) {
        setTimeout((x) => {
          elements.value.options = props.tableData;
        }, 500);
      }
      let tempUser = localStorage.getItem("user") as any;
      userOffices.value = JSON.parse(tempUser).offices;
    });

    const loadOfficeData = (id, index) => {
      ApiService.get("/api/offices/" + id).then(function (response) {
        dataTable.value[index].remarks = response.data.remarks;
      });
    };

    return {
      fieldSelector,
      loadOfficeData,
      selector,
      elements,
      dataTable,
      loading,
      headers,
      headerTable,
      contactSelected,
      selectOffices,
      inputOffice,
      userOffices,
      canDeleteOffices,
    };
  },
};
