
import FlagSelect from "@/components/catalogs-select/FlagSelect.vue";
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";

export default {
  name: "SynchronizeTE",
  components: {
    FlagSelect,
  },
  props: {
    data_table: String,
  },
  emits: ["createElement", "removeElement"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const flagsData = computed(() => store.getters.SyncFlag);
    const addElement = ref(false);
    const isLoading = ref(false);
    const tableData = ref<[] | any>([]);
    const officeData = computed(() => store.getters.Office);
    const inputOffice = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;
    const headerTable = ref([
      { column: t("synchronizeflagid"), key: "synchronize_flag_id" },
      { column: t("tiEntityTypes"), key: "entity_type_id" },
      { column: t("ioffice"), key: "office_id" },
      { column: t("externalcode"), key: "external_code" },
    ]);

    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };

    const createElement = () => {
      emit("createElement");
    };

    const getEntities = () => {
      ApiService.get("/api/type-entities/lists?per_page=1000").then(function (
        response
      ) {
        elements.value.options = response.data.type_entities;
      });
    };

    const selectOffice = (query) => {
      if (query !== "") {
        inputOffice.value.loading = true;
        setTimeout(() => {
          inputOffice.value.loading = false;
          store.commit("setLoading", false);
          ApiService.query("api/offices/lists", {
            params: {
              per_page: 25,
              name: query,
              users: [user.value.id],
            },
          }).then(({ data }) => {
            inputOffice.value.options = data.offices;
            inputOffice.value.loading = false;
          });
        }, 200);
      }
    };

    const createSync = () => {
      addElement.value = true;
      if (tableData.value.length > 0) {
        let invalidTable = false;
        tableData.value.forEach((item, index) => {
          if (index === tableData.value.length - 1) {
            invalidTable =
              item.synchronize_flag_id === "" || item.external_code === "";
          }
        });
        if (!invalidTable) {
          addLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rflagtabletable"),
            type: "error",
          });
        }
      } else {
        addLine();
      }
    };

    const addLine = () => {
      tableData.value.push({
        synchronize_flag_id: "",
        office_id: "",
        entity_type_id: "",
        external_code: "",
      });
    };

    const removeElement = (index) => {
      tableData.value.splice(index, 1);
    };

    const flagsDataFunction = (flag_synchronize) => {
      let flagsDataIds = flagsData.value.map((x) => x.id);
      if (flag_synchronize && !flagsDataIds.includes(flag_synchronize.id)) {
        store.commit("addSyncFlag", flag_synchronize);
      }
      return flagsData.value;
    };

    watch(
      () => props.data_table,
      (first) => {
        tableData.value = first;

        const offices_id = inputOffice.value.options.map((x) => x.id);
        first.forEach((item, index) => {
          console.log(item.office_id);
          if (
            !offices_id.includes(item.office_id) &&
            item.office_id &&
            inputOffice.value.options.length > 0
          ) {
            ApiService.get("api/offices/" + item.office_id).then(({ data }) => {
              inputOffice.value.options.push(data);
              inputOffice.value.loading = false;
            });
          }
        });
      },
      { deep: true }
    );

    onMounted(() => {
      tableData.value = props.data_table;
      selectOffice(" ");
      getEntities();
    });

    return {
      addElement,
      flagsData,
      isLoading,
      tableData,
      headerTable,
      inputOffice,
      elements,
      selectOffice,
      createSync,
      removeElement,
      createElement,
      flagsDataFunction,
    };
  },
};
