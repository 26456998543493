
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default {
  name: "SectorsTE",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement", "saveElement"],

  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const dataTable = computed(() => store.getters.Sectors);
    const selector = ref([]);

    const headers = ref([
      { label: "iname", prop: "name" },
      { label: "idescription", prop: "description" },
    ]);
    const headerTable = ref(headers.value);
    const addElement = (value) => {
      if (dataTable.value.find((o) => o.id === value.id) === undefined) {
        store.commit("createSector", {
          id: value.id,
          name: value.name,
          description: value.remarks,
        });
      }
    };
    const fieldSelector = () => {
      if (selector.value.find((o) => o === 0) === 0) {
        elements.value.options.forEach(function (value) {
          addElement(value);
          selector.value = [];
        });
      } else {
        selector.value.forEach(function (value) {
          let data = elements.value.options.find((o) => o.id === value);
          addElement(data);
          selector.value = [];
        });
      }
    };

    const getData = () => {
      //
    };
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const contactSelected = ref({
      contact_type_id: 1,
      entities: route.params.id,
    });
    onMounted(() => {
      getData();
      ApiService.get("/api/sectors").then(function (response) {
        elements.value.options = response.data;
      });
    });

    return {
      fieldSelector,
      selector,
      elements,
      dataTable,
      loading,
      headers,
      headerTable,
      contactSelected,
    };
  },
};
