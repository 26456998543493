
import { useI18n } from "vue-i18n";
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";

export default {
  name: "CommunicationSectionTE",
  props: {
    data_table: String,
  },
  emits: ["createElement", "removeElement"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const addElement = ref(false);
    const isLoading = ref(false);
    const tableData = ref<[] | any>([]);
    const headerTable = ref([
      {
        column: "imedia",
        key: "media_name",
      },
      { column: "ivalue", key: "value" },
      { column: "iremarks", key: "remark" },
    ]);
    const elements = ref({
      loading: false,
      options: [],
      list: [],
    }) as any;

    const createElement = () => {
      emit("createElement");
    };

    const removeElement = (index) => {
      tableData.value.splice(index, 1);
    };

    watch(
      () => props.data_table,
      (first) => {
        tableData.value = first;
      },
      { deep: true }
    );

    onMounted(() => {
      tableData.value = props.data_table;
      ApiService.get("/api/media/lists/?per_page=1000").then(function (
        response
      ) {
        elements.value.options = response.data.media;
      });
    });

    return {
      addElement,
      isLoading,
      tableData,
      headerTable,
      elements,
      removeElement,
      createElement,
    };
  },
};
